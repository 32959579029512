<template>
    <app-dialog-info
            class="app-dialog-choose-offers"
            v-model="show"
            v-if="show"
            :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Simulate Usage</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="error" :message="error"></app-error>
                <div class="content">
                    <app-select :options="simulateOptions" key-value="value" key-title="label" v-model="simulateType"></app-select>
                    <app-input :label="simTypeLabel" v-model="amt" type="number" ></app-input>
                </div>
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="simulateUsage">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appSelect from '@/components/app-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true },
        maxWidth: { type: [Number, String], default: 610 },
        uuid:     { type: String, required: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
        appSelect
    },

    data() {
        return {
            amt: 0,
            simulateType: 0,
	// SimulateType_VOICE SimulateType = 0
	// SimulateType_TEXT  SimulateType = 1
	// SimulateType_DATA  SimulateType = 2
            simulateOptions: [
                {value: 0, label: 'Voice'},
                {value: 1, label: 'Text'},
                {value: 2, label: 'Data'}
            ],
            loading: false,
            error: null,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),
        simTypeLabel () {
            let label = 'Amount'
            switch (this.simulateType) {
                case 0: 
                label = 'Amount (Minutes)'
                break
                case 1: 
                label = 'Amount (Texts)'
                break
                case 2: 
                label = 'Amount (Megabytes)'
                break
            }
            return label
        },
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
    watch: {
        value (isShowing) {
            // if (isShowing) {
            //     this.loadOffers()
            // }
        }
    },

    methods: {
        simulateUsage() {
            let params = {
                SPID: this.current_spid,
                DeviceUUID: this.uuid,
                Type: this.simulateType,
                Amount: Number(this.amt),
                ExecuteMode: 1 
            }

            this.error = null
            this.loading = true
            this.$store.dispatch('api_matrixx/Simulate', params).then(response => {
                console.log('resp', response)
                this.loading = false
                this.$emit('close')
            }).catch(error => {
                console.error(error)

                this.offers = []

                this.loading = false
            })
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-choose-offers {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        .is-bold {
            font-weight: bold;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-choose-offers {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>