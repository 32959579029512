<template>
    <div id="page-matrixx-sp-group-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link v-if="customer_uuid" class="btn btn-back-to" :to="{ name: 'matrixx-customer-group-details', params: { uuid: customer_uuid} }">Back to customer</router-link>
                    <router-link v-else class="btn btn-back-to" :to="{ name: 'matrixx-devices' }">Back to Devices</router-link>

                    <div class="header-info">
                        <h1 class="heading">Device Details</h1>
                    </div>
                </div>
            </div>

            <div v-if="details" class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.group"
                        :data="details"
                        title="Details"
                    />
                    <div class="buttons-row">
                        <button class="btn btn-primary" @click="showSimulateDialog">Simulate Usage</button>
                    </div>
                </div>

            </div>
        </div>
        <simulate-dialog :uuid="details_uuid" v-model="isShowingSimulateUsageDialog" @close="hideSimulateDialog" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import simulateDialog from '@/views/matrixx/device-details/simulate-dialog'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        simulateDialog
    },

    data() {
        return {
            details: null,
            isShowingSimulateUsageDialog: false,

            rows: {
                group: [
                    { key: 'IMSI', title: 'IMSI' },
                    { key: 'IMEI', title: 'IMEI' },
                    { key: 'MtxObjectID', title: 'MtxObjectID' },
                    { key: 'CreatedAtNanos', title: 'Date Created', type: 'datenanos' },
                    { key: 'UpdatedAtNanos', title: 'Last Updated', type: 'datenanos' },
                ],
            },

            loading: false,

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadDetails()
        },
        
        loadDetails () {
            if (this.details_uuid) {
                this.loading = true
                this.$store.dispatch('api_matrixx/GetMtxDeviceByUUID', { UUID: this.details_uuid, SPID: this.current_spid }).then(resp => {
                    this.details = resp
                    this.loading = false
                }).catch(error => {
                    console.error(error)
                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({ name: 'matrixx-devices', replace: true })
                    }
                })
            }
        },

        showSimulateDialog () {
            this.isShowingSimulateUsageDialog = true
        },

        hideSimulateDialog () {
            this.isShowingSimulateUsageDialog = false
        },
    },

    watch: {
        details_uuid(val) {
            if (val) {
                this.loadDetails()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),

        customer_uuid () {
          return this.$route.params.customer_uuid || ''
        },

        details_uuid() {
            return this.uuid || this.$route.params.uuid
        },
    },
}
</script>

<style lang="scss">
#page-matrixx-sp-group-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .details {
        margin-bottom: 30px;

        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:last-child { margin: 0 };
    }

    .app-table-details {
        margin-bottom: 22px;;
    }
}

@media (max-width: $tablet-size) {
    #page-matrixx-sp-group-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-matrixx-sp-group-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            margin-bottom: 15px;

            .col-6,
            .col-12,
            .col-mob-12 {
                margin-bottom: 15px;
            }
        }
    }
}
</style>